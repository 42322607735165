import axios from '@/common/axios'
import qs from 'qs'

// 新增敏感词
export function reqAddSensitiveWords (data) {
  return axios({
    method: 'post',
    url: '/sensitiveWord/add',
    data: qs.stringify(data)
  })
}

// 删除敏感词
export function reqDeleteSensitiveWords (query) {
  return axios({
    method: 'delete',
    url: '/sensitiveWord/delete',
    params: query
  })
}

// 修改敏感词
export function reqUpdateSensitiveWords (data) {
  return axios({
    method: 'put',
    url: '/sensitiveWord/update',
    data: qs.stringify(data)
  })
}

// 获取敏感词列表
export function reqSensitiveWordsList (query) {
  return axios({
    method: 'get',
    url: '/sensitiveWord/list',
    params: query
  })
}
