<template>
  <div id="comment">
    <!-- 搜索区 -->
    <el-form inline size="small">
      <el-form-item label="敏感词">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入敏感词"
          clearable
          @keyup.enter.native="handleSearch"
        />
        <el-input type="text" style="display: none" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" @click="addSensitiveWords">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table
      :data="tableData"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column
        type="index"
        label="序号"
        width="200"
        align="center"
      />
      <el-table-column prop="name" label="敏感词名称" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.name">{{ scope.row.name }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click="updateSensitiveWords(row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click="deleteSensitiveWords(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增或修改的弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="ruleForm" style="width: 80%" :model="dataForm">
        <el-form-item label="敏感词" label-width="100px">
          <el-input v-model="dataForm.name" autocomplete="off" placeholder="请输入敏感词" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="reviewFormSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分页 -->
    <el-pagination
      :total="total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { reqAddSensitiveWords, reqDeleteSensitiveWords, reqUpdateSensitiveWords, reqSensitiveWordsList } from '@/api/main/sensitivewords.js'

export default {
  data () {
    return {
      // 表格数据
      tableData: [],
      dataForm: {
        id: '',
        name: ''
      },
      //总数据条数
      total: 0,
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        id: ''
      },
      title: '', //弹框标题
      centerDialogVisible: false //控制弹框显示
    }
  },
  created () {
    this.getSensitiveWordsList()
  },
  methods: {
    // 获取表格数据
    getSensitiveWordsList () {
      reqSensitiveWordsList(this.searchForm).then((res) => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    // 修改当前页触发的回调
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      this.getSensitiveWordsList()
    },
    // 每页展示的数据发生改变时触发的回调
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      this.getSensitiveWordsList()
    },
    // 点击搜索触发的回调
    handleSearch () {
      this.searchForm.pageNum = 1
      this.getSensitiveWordsList()
    },
    // 点击新增触发的回调
    addSensitiveWords () {
      this.centerDialogVisible = true
      this.dataForm.name = ''
      this.title = '新增敏感词'
    },
    // 点击确认触发的回调
    async reviewFormSubmit () {
      if (this.dataForm.id !== '') {
        await reqUpdateSensitiveWords(this.dataForm)
        this.centerDialogVisible = false
        this.dataForm.id = ''
      } else {
        await reqAddSensitiveWords(this.dataForm)
        this.centerDialogVisible = false
      }
      this.getSensitiveWordsList()
    },
    // 点击删除触发的回调
    deleteSensitiveWords (row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await reqDeleteSensitiveWords({ id: row.id })
        this.getSensitiveWordsList()
      })
    },
    // 点击修改触发的回调
    updateSensitiveWords (row) {
      this.dataForm.name = row.name
      this.dataForm.id = row.id
      this.centerDialogVisible = true
      this.title = '修改敏感词'
    }
  }
}
</script>
